import { createSlice } from "@reduxjs/toolkit";
import { getShareLinkList, sharedAuthenticate, deleteShareLink } from "../actions/shareLink";

const initialState = {
  shareLinkList: [],
  getListLoading: false,
  authentication: [],
  isLoggedin: false,
  deleteLoading: false,
};
const sharlinkSlice = createSlice({
  name: "Sharelink",
  initialState,
  extraReducers: {

    // Get Sharelinks List
    [getShareLinkList.pending]: (state) => {
      state.getListLoading = true;
    },
    [getShareLinkList.fulfilled]: (state, action) => {
      state.getListLoading = false;
      state.shareLinkList = action.payload || []
    },
    [getShareLinkList.rejected]: (state, action) => {
      state.getListLoading = false;
    },    
    // Delete ShareLink
    [deleteShareLink.pending]: (state) => {
      state.deleteLoading = true;
    },
    [deleteShareLink.fulfilled]: (state, action) => {
      state.deleteLoading = false;
      state.shareLinkList = state.shareLinkList.filter(item => item._id != action.meta.arg)
    },
    [deleteShareLink.rejected]: (state, action) => {
      state.deleteLoading = false;
    },
  },
  reducers: {
    resetShareLinkList: (state, action) => {
      state.shareLinkList = [];
    },
    setLogin: (state, action) => {
      state.isLoggedin = true
    },
  }
});

export default sharlinkSlice.reducer;
export const { resetShareLinkList, setLogin } = sharlinkSlice.actions;
