import { Navigate } from "react-router-dom";
import BlankLayout from "src/layouts/BlankLayout";
import ActivateAccount from "src/pages/ActivateAccount";
import ForgotPassword from "src/pages/ForgotPassword";
import GenerateToken from "src/pages/GenerateToken";
import Login from "src/pages/Login";

import PresentationPreviewPublic from "src/pages/presentations/PresentationPreviewPublic";
import SharedLink from "src/pages/sharedLink/SharedLink";

export const publicRoutes = [
  
  {
    path: "/shared/:hash",
    element: <SharedLink />,
  },
  {
    path: "/preview/",
    element: <PresentationPreviewPublic />,
  },
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/set-password", element: <ActivateAccount /> },
  { path: "/generate-token", element: <GenerateToken /> },
];
