import { useState, memo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setLogin } from "src/store/reducers/sharelink-slice";
import { sharedAuthenticate } from "src/store/actions/shareLink";
import InputFields from "../../components/ui/formElements/InputFields";
import useAuthShareLink from "src/pages/sharedLink/useAuthSharedLink";
import Divider from "src/components/ui/Divider";

const AuthPasswordForm = ({ hashDetails }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { password: "" },
  });

  const { addShareLinkId } = useAuthShareLink();
  const password = watch("password");

  const loginUserHandler = () => {
    setIsLoading(true);
    dispatch(sharedAuthenticate({ id: hashDetails._id, password }))
      .then((res) => {
         if(res.payload){
          setIsLoading(false);
          console.log('hashDetails', hashDetails)
          addShareLinkId(hashDetails);
          dispatch(setLogin());
         }
        
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <form style={{ width: "clamp(250px, 30vw + -100px, 500px)" }}>
      <Box className="AuthPasswordForm">
        <Typography variant="h6" className="sign-in-text">
          Authenticate
        </Typography>
        <Typography variant="body2">
          Required password to view this page, check your email to get password
        </Typography>
        <Divider sx={{ m: 2 }}></Divider>
        <InputFields
          fieldName="password"
          label="Password"
          control={control}
          rules={{
            required: "Password is required",
          }}
          error={errors?.password}
          type="password"
        />

        <Box className="pt-4">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ letterSpacing: "1.2px" }}
            onClick={handleSubmit(loginUserHandler)}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ marginLeft: 1 }} />
            ) : (
              "Continue"
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default memo(AuthPasswordForm);
