import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Icons
import { IoShare, IoTrash, IoOpen, IoPencil } from "react-icons/io5";
import { MdCloudUpload, MdFileDownload } from "react-icons/md";
import { IoIosDownload } from "react-icons/io";
import { AiFillLike, AiOutlineLink } from "react-icons/ai";
import OpenWithIcon from '@mui/icons-material/OpenWith';
//APIs
import { cloneLandingPage, downloadSource } from "src/store/actions/microsites";

//MUI Componenets
import ProjectCard from "src/components/common/ProjectCard";
import CardControls from "src/components/microsites/CardControls";

//Dialogs
import { setLandingPagePreview } from "src/store/reducers/microsite-slice";

const LandingPageCard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { microsite, showDetails, openDialog, role, setMoveableItem } = props;
  const { roles } = useSelector((state) => state.roles);

  const openPreviewHandler = useCallback((micrositeId) => {
    dispatch(setLandingPagePreview({ isOpen: true, micrositeId }));
  }, []);

  const handleClone = useCallback((id) => {
    openDialog(id, "clone");
  }, []);

  const downloadHandler = useCallback((id) => {
    dispatch(downloadSource(id));
  }, []);

  const publishHandler = useCallback((id) => {
    navigate(`/microsite/${id}/publish`);
  }, []);

  //dropdown on card options
  const cardItemDropData = useCallback((role, status) => {
    const sendReview = {
      label: "Send for Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "review", microsite);
      },
      permissionPath:'approvalProcess'
    };

    const publish = {
      label: "Publish",
      icon: <IoOpen />,
      onClick: publishHandler,
    };

    const cancelReview = {
      label: "Cancel Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "cancelReview");
      },
      permissionPath: "approvalProcess"
    };
    const dropData = [
      {
        label: "Share Microsite",
        icon: <IoShare />,
        onClick: (id) => {
          openDialog(id, "share", microsite);
        },
        permissionPath: 'share'
      },
      {
        label: "Share Link",
        icon: <AiOutlineLink />,
        onClick: (id) => {
          openDialog(id, "shareLink", microsite);
        },
        permissionPath: 'share'
      },
      {
        label: "Upload New Version",
        icon: <MdCloudUpload />,
        onClick: (id) => {
          openDialog(id, "uploadNewVersion");
        },
        permissionPath: 'microsites.versions'
      },
      {
        label: "Download Source",
        icon: <MdFileDownload />,
        onClick: downloadHandler,
        permissionPath: 'microsites.export.downloadSourceCode'
      },
      {
        label: "Export",
        icon: <IoIosDownload />,
        onClick: (id) => {
          openDialog(id, "export", microsite);
        },
      },
      {
        label: "Move To",
        icon: <OpenWithIcon />,
        onClick: () => {
          setMoveableItem(microsite);
        },
        disabled: microsite.isLock
      },
      {
        label: "Delete",
        icon: <IoTrash />,
        onClick: (id) => {
          if (status === "reviewed" || status === "inReview") {
            openDialog(id, "reviewdelete");
          } else {
            openDialog(id, "delete");
          }
        },
      },
      {
        label: "Rename",
        icon: <IoPencil />,
        onClick: (id) => {
          console.log('microsite', microsite)
          openDialog(id, "renameMicrosite", microsite);
        },
      },
    ];

    if (status !== "reviewed") {
      if (status === "inReview") {
        dropData.splice(1, 1);
        dropData.unshift(cancelReview);
      } else {
        dropData.unshift(sendReview);
      }
    }
    if (status == "reviewed") {
      // console.log(dropData, status);
      dropData.splice(1, 1);

    }

    // Publish Option Code
    // if (roles[role]?.publish) {
    //   dropData.push(publish);
    // }
    return dropData;
  }, []);

  return (
    <ProjectCard
      key={microsite._id}
      id={microsite._id}
      image={`${microsite.bucketURL}/${microsite?.thumbnail}`}
      title={microsite.name}
      subtitle={`v${microsite.version} ${microsite.lang}`}
      description={microsite.description}
      status={microsite.status}
      onDoubleClick={() => navigate(`/microsite/${microsite._id}/edit`)}
      classes="landingPageCard"
      isShared={microsite.isShared}
      Controls={
        <CardControls
          id={microsite._id}
          status={microsite.status}
          clone={() => handleClone(microsite._id)}
          toggleDetails={() => showDetails(microsite)}
          dropDownData={cardItemDropData(role, microsite.status)}
          preview={() => openPreviewHandler(microsite._id)}
          role={role}
        />
      }
    />
  );
};
export default React.memo(LandingPageCard);
