import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ChooseAsset from "src/components/dialogs/ChooseAsset";
import Control from "./Control";
import { Box } from "@mui/material";
import { MdOutlineUpload } from "react-icons/md";
import { IoCloudUpload } from "react-icons/io5";
import { useRef } from "react";
import { HISTORY_ACTIONS } from "src/data/contants";
import Image from "src/components/ui/Image";
import { posterOfVideo, setImageLayerWidth } from "src/services/utils";
import { isEditor } from "src/services/editor.service";
import Row from "src/components/properties/Row";
import Info from "src/components/ui/Info";
import AddLink from "./link/AddLink";
import Width from "./Width";

const AssetUpload = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { elementId, updateType } = props;
  const imgRef = useRef();
  let element = props.element || document.getElementById(elementId);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const updateAssetHandler = (src) => {
    const updateSRC = ({
      src,
      el = element,
      setSrc = true,
      setDataSrc = true,
    }) => {
      if (setSrc) {
        el.src = src;
        //set selected image width or layer width based on editor type
        setImageLayerWidth(el);
      } else {
        el.removeAttribute("src");
      }

      if (setDataSrc) {
        el.dataset.src = src;
      } else {
        el.removeAttribute("data-src");
      }
    };

    const updateBackground = (url) => {
      element.style.background = "";
      element.style.backgroundImage = `url(${url})`;
      props.onSrcUpdate(url);
    };

    const updateActions = {
      image(src) {
        updateSRC({ src });
      },

      background(src) {
        updateBackground(src);
      },

      audio(src) {
        if (element.nodeName === "AUDIO") {
          updateSRC({ src });
          updateSRC({ src, el: element.closest(".cs-video") });
        } else {
          const childAudio = element.querySelector("audio");
          if (childAudio) {
            updateSRC({ src, el: childAudio });
            updateSRC({ src, el: element });
          }
        }
      },

      video(src) {
        if (element.nodeName === "VIDEO") {
          updateSRC({ src });
          updateSRC({ src, el: childVideo.closest(".cs-video") });
        } else {
          const videoTag = element.querySelector("video");
          if (videoTag) {
            updateSRC({ src, el: element, setSrc: false, setDataSrc: false }); // set cs-video
            updateSRC({ src, el: videoTag, setSrc: true, setDataSrc: true }); // set video tag inside cs-video element
            this.poster(src);
            // videoTag.play()
          }
        }
      },

      list(src) {
        try {
          const li = element.querySelectorAll("li");
          const { externalData } = props;
          li.forEach((element) => {
            const img = `<img style = "${externalData.imageStyles}" src = "${src}" />`;
            const text = element.querySelector("span");
            element.innerHTML = img;
            element.insertAdjacentElement("beforeend", text);
          });
        } catch (error) {
          console.log(error);
        }
      },

      poster(src) {
        let poster = posterOfVideo(src);
        if (element.nodeName === "VIDEO") {
          element.poster = poster;
          element.dataset.poster = poster;
        } else {
          const childVideo = element.querySelector("video");
          if (childVideo) {
            childVideo.poster = poster;
            childVideo.dataset.poster = poster;
          }
        }
      },
    };
    console.log('updateType', updateType)
    updateActions[updateType] &&
      (() => {
        updateActions[updateType](src);
        let history_Action = HISTORY_ACTIONS.STYLED_ACTIONS.common
        if(updateType === 'image'){
          history_Action = HISTORY_ACTIONS.imageChange
        }else if(updateType === 'video' || updateType === 'poster'){
          history_Action = HISTORY_ACTIONS.updated
        }else{
          HISTORY_ACTIONS.STYLED_ACTIONS.common
        }
        props.updateHistory({action: history_Action});
        imgRef.current && (imgRef.current.src = src);
      })();
  };

  const getSrc = () => {
    const previews = {
      image: (el) => {
        return el.src;
      },
      poster: (el) => {
        if (el?.poster) {
          return el.poster;
        } else {
          const videoPoster = el.querySelector("video")?.poster;
          if (videoPoster) {
            return videoPoster;
          }
        }
      },
      background: (el) => {
        const ImageBackground = el.style.backgroundImage;
        if (ImageBackground !== "none") {
          return ImageBackground.substring(5, ImageBackground.length - 2);
        }
      },

      list: (el) => {
        return el.querySelector("img")?.src;
      },

      default:
        "https://createmart-seed-folder.s3.amazonaws.com/placeholders/image-comp/cs-default-image.jpg",
    };

    return (
      (element && previews[updateType] && previews[updateType](element)) ||
      previews.default
    );
  };

  useEffect(() => {
    if (element) {
      setAlt(element.getAttribute("alt") || "");
    }
  });

  const [alt, setAlt] = useState("");
  const altHandler = (e) => {
    const value = e.target.value;
    element.setAttribute("alt", value);
    setAlt(e.target.value);
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isPlaceholderImage, setIsPlaceholderImage] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    setDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });

    //once image is loaded, check is image changed or still placeholder image, this state is using actual size of image
    if (!e.target.src.includes("placeholder")) {
      setIsPlaceholderImage(false);
    } else {
      setIsPlaceholderImage(true);
    }
    setIsImageLoaded(true)
  };

  return (
    <Control
      heading={props.isCollapse !== undefined ? "" : "Select Asset"}
      className="image"
      divider={false}
      expanded={true}
      isCollapse={props.isCollapse === undefined ? true : props.isCollapse}
    >
      {dimensions.width > 0 && isPlaceholderImage === false && (
        <Info
          infoTooltip={`The image actual height is ${dimensions.height}px, and width is ${dimensions.width}px`}
        >{`Height ${dimensions.height}px, Width ${dimensions.width}px`}</Info>
      )}
      <Box className="global-btn">
        {["image", "background", "poster", "list"].includes(updateType) ? (
          <>
            <Box className="image-bg">
              <Button className="upload" onClick={toggleDialog}>
                <IoCloudUpload className="upload-icon" />
              </Button>
              <img
                ref={imgRef}
                width="100%"
                onError={() => {
                  imgRef.current.src =
                    "https://createmart-seed-folder.s3.amazonaws.com/placeholders/image-comp/cs-default-image.jpg";
                }}
                onLoad={handleImageLoad}
                src={getSrc()}
              />

              {/* <img ref={imgRef} width="100%"  src={getSrc()} /> */}
            </Box>
          </>
        ) : (
          <Button fullWidth variant="contained" onClick={toggleDialog}>
            Upload {updateType !== "src" ? updateType : props.assetFormat}
            <MdOutlineUpload className="icon" size="18px" />
          </Button>
        )}
      </Box>

      <ChooseAsset
        assetFormat={props.assetFormat}
        updateHistory={props.updateHistory}
        show={isOpen}
        setSrc={updateAssetHandler}
        type="edit"
        close={toggleDialog}
      />
      {!isEditor.presentation() && (
        <Row
          label="Alt Text"
          element={
            <input
              type="text"
              name="left"
              className="input fullWidth"
              value={alt}
              onChange={altHandler}
              placeholder="Type alternative text.."
              onBlur={() => {
                props.updateHistory(HISTORY_ACTIONS.imageChange);
              }}
            />
          }
        />
      )}
      {/* not for presentation and only for image component*/}
      {document.getElementById(elementId)?.closest(".image-component") &&
        !isEditor.presentation() && (
          <>
            <AddLink
              elementId={elementId}
              updateHistory={props.updateHistory}
              undoRedoChange={props.undoRedoChange}
            />
            <Width
              collapseTitle={"Image Size"}
              isCollapse={false}
              elementId={elementId}
              updateHistory={props.updateHistory}
              undoRedoChange={props.undoRedoChange}
            />
          </>
        )}
    </Control>
  );
};

export default AssetUpload;

AssetUpload.defaultProps = {
  assetFormat: "image",
  updateType: "image",
};
