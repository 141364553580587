import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
const useAuthShareLink = () => {
  const dispatch = useDispatch();
  const [authData, setAuthData] = useState(null);
  const { authention: sharedAuthFromRedux, isLoggedin } = useSelector((state) => state.shareLink);

  // Fetch data from localStorage and set it in Redux
  useEffect(() => {
    const storedData = localStorage.getItem("sharedAuth");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAuthData(parsedData);
    }
  }, [dispatch]);

  const addShareLinkId = (shareLinkObject) => {
    console.log(shareLinkObject)
    const hash = shareLinkObject.links.previewHash
    //store hash in cookie
    cookie.save(hash, hash, {
      path: "/",
      maxAge: 3600, // 1 hour
    });

    // Fetch existing data from localStorage
    let storageIds = localStorage.getItem("sharedAuth");
    storageIds = storageIds ? JSON.parse(storageIds) : [];

    // Check if the shareLinkId already exists
    const idExists = storageIds.some((item) => item.links.previewHash === hash);
    if (!idExists) {
      storageIds.push(shareLinkObject);
      localStorage.setItem("sharedAuth", JSON.stringify(storageIds));
    }
    
  };

  //use when user reload page
  const isSharedLinkAuthenticated = (hash) => {
    const isHashInCookie = cookie.load(hash);
    if (isHashInCookie) {
      return getData(hash)
    } else {
      return false;
    }
    
  }
  
  const getData = (hash)=>{
    let storageIds = localStorage.getItem("sharedAuth");
    if (storageIds) {
      storageIds = storageIds ? JSON.parse(storageIds) : [];
      return (storageIds.find((item) => item.links.previewHash === hash));
    } else {
      return false
    }
  }
  return { authData, addShareLinkId, getData, isSharedLinkAuthenticated, isLoggedin };
};

export default useAuthShareLink;
