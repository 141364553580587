import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import "../../styles/properties.scss";
import { IoRefreshSharp } from "react-icons/io5";
import TooltipWrapper from "../ui/Tooltip";
import Grid from "@mui/material/Grid2";
import "./Row.scss";

const Row = (props) => {
  const {
    label,
    element,
    component,
    leftsx,
    rightsx,
    button,
    clearProperty,
    canvasElement,
    onClear,
  } = props;

  return (
    <Box className="row-property">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid size={leftsx ? Number(leftsx) : 'auto'}>
          {button ? (
            button
          ) : (
            <Typography component={component ? component : "div"}>
              {label}
            </Typography>
          )}
        </Grid>
        <Grid size={rightsx ? Number(rightsx) : 'auto'} className="text-right">
          {clearProperty && canvasElement && (
            <TooltipWrapper title="Clear">
              <IconButton
                onClick={() => {
                  canvasElement.style[clearProperty] = "";
                  if (onClear) {
                    onClear();
                  }
                }}
              >
                <IoRefreshSharp fontSize="small" />
              </IconButton>
            </TooltipWrapper>
          )}
          {element}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Row);
