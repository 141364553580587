import React from "react";
import ViewMicrosite from "./ViewMicrosite";

export const View = ({hashDetails}) => {
  return (
    <div className="sharedPreview">
      {hashDetails && hashDetails.type === "landingpage" && (
        <ViewMicrosite id={hashDetails?.landingPageId} UID={hashDetails.UID} />
      )}
    </div>
  );
};
