import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { Slider } from "@mui/material";
import Control from "../Control";
import { convertHexToRgba, convertRgbAToHexAplha } from "src/services/editor.service";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Check_Box from "src/components/common/Check_Box";

const ColorBackground = (props) => {
  let {
    elementId,
    undoRedoChange,
    bgColorCheckbox,
    setbgColorCheckbox,
    setbgImageCheckbox,
    updateHistory,
    selector,
  } = props;

  const [color, setColor] = useState("#ffffff");
  const [opacity, setOpacity] = useState(1);
  const element = selector
    ? document.getElementById(elementId).querySelectorAll(`${selector}`)
    : [document.getElementById(elementId)];

  useEffect(() => {
    const bgColor = getComputedStyle(element[0]).backgroundColor;
    if (bgColor) {
      if (bgColor !== "rgba(0, 0, 0, 0)") {
        if (bgColor.includes("rgb(")) {
          const rgb = convertRgbAToHexAplha(bgColor).hex;
          setColor(rgb);
          setOpacity(1);
        } else if (bgColor.includes("rgba(")) {
          const rgba = convertRgbAToHexAplha(bgColor);
          setColor(rgba.hex);
          setOpacity(parseFloat(rgba.aplha));
        }
      }
    }
  }, [elementId, undoRedoChange]);

  let timeout;
  const colorHandler = (e) => {
    clearTimeout(timeout);
    let { name, value } = e.target;
    if (bgColorCheckbox.opacity === true) {
      let rgba = convertHexToRgba(value, opacity);
      element.forEach((elem) => {
        elem.style.backgroundColor = rgba;
      });
      const rgbColor = convertRgbAToHexAplha(rgba).hex;
      timeout = setTimeout(() => {
        setColor(value);
      }, 100);
    } else {
      element.forEach((elem) => {
        elem.style.backgroundColor = value;
      });
      timeout = setTimeout(() => {
        setColor(value);
      }, 100);
    }
    updateHistory();
  };

  const opacityCheckbox = (e) => {
    if (e.target.checked) {
      setbgColorCheckbox((prev) => ({
        ...prev,
        opacity: !bgColorCheckbox.opacity,
      }));
      setbgImageCheckbox(false);
      const bgImage = element[0].style.backgroundImage;
      if (bgImage.includes("url")) {
        element.forEach((elem) => {
          elem.style.backgroundImage = "";
        });
      }
      if (element[0].getAttribute("id") === "dropzone") {
        element.forEach((elem) => {
          elem.style.backgroundImage = "none";
        });
      }
    } else {
      setbgColorCheckbox((prev) => ({
        ...prev,
        opacity: !bgColorCheckbox.opacity,
      }));
      setOpacity(1);
      element.forEach((elem) => {
        elem.style.background = "";
      });

      element.forEach((elem) => {
        elem.style.backgroundColor = color;
      });

      if (element[0].getAttribute("id") === "dropzone") {
        element.forEach((elem) => {
          elem.style.backgroundImage = "none";
        });
      }
    }
  };

  const opacityHandler = (e) => {
    let { name, value } = e.target;
    let backgroundOpacity = convertHexToRgba(color, value);
    element.forEach((elem) => {
      elem.style.background = "";
    });
    element.forEach((elem) => {
      elem.style.backgroundImage = "none";
    });
    element.forEach((elem) => {
      elem.style.backgroundColor = backgroundOpacity;
    });
    setOpacity(value);
    updateHistory();
  };

  return (
    <>
      <Control divider={false}>
        <Row
          label="Color"
          element={
            <input
              type="color"
              name="color"
              className="color"
              value={color}
              onInput={colorHandler}
              disabled={!bgColorCheckbox.color}
            />
          }
        />
        <Row
          label="Transparency"
          element={
            <Check_Box
              color="secondary"
              size="small"
              checked={bgColorCheckbox.opacity}
              onClick={opacityCheckbox}
              disabled={!bgColorCheckbox.color}
            />
          }
        />
        <Row
          label="Value"
          leftsx={5}
          rightsx={5}
          element={
            <Slider
              className="slider"
              size="small"
              name="opacity"
              defaultValue={1}
              valueLabelDisplay="auto"
              step={0.1}
              marks
              min={0}
              max={1}
              disabled={!bgColorCheckbox.opacity || !bgColorCheckbox.color}
              value={opacity}
              onChange={opacityHandler}
            />
          }
        />
      </Control>
    </>
  );
};

export default ColorBackground;
