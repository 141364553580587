import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoSend, IoCloseSharp, IoInformationCircle } from "react-icons/io5";
import {
  Typography,
  Autocomplete,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Grid2,
  IconButton,
} from "@mui/material";
import Main from "../Main";
import Button from "src/components/Button";
import { uiActions } from "src/store/reducers/ui-slice";
import Input from "src/components/ui/formElements/Input";
import { AiOutlineCopy } from "react-icons/ai";
import TooltipWrapper from "src/components/ui/Tooltip";
import UserList from "src/components/common/UserList";
import { copyText } from "src/utils/Utils";
import {
  dateInSecond,
  disablePastDate,
  formatIsoDate,
  getCurrentDate,
  useDateFormat,
} from "src/hooks/useDateFormat";
import { getLinkDetails, linkSharedToUsers } from "src/store/actions/shareLink";
import { apiURL } from "src/interfaces/api.interface";
import useFilteredUserList from "src/components/common/useUserList";

const ShareLinkDialog = (props) => {
  const { isOpen, handleClose, type, shareObjectId, version } = props;
  const dispatch = useDispatch();
  const [linkDetails, setLinkDetails] = useState({});
  const [password, setPassword] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { filteredUserList: userList, loading } = useFilteredUserList();
  // console.log('userList',userList)
  //store
  const UID = useSelector((state) => state.auth.userData.company.UID);
  //helper state
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [isExpiryDate, setIsExpiryDate] = useState(false);
  //remove current login user and the user has developer role
  const linkRef = useRef();

  const linkFormat = (hash) => {
    return `${window.location.host}/shared/${hash}`;
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const expireDateHandler = (e) => {
    const value = e.target.value;
    setExpiryDate(value);
  };

  const usersChangeHanlder = (users) => {
    setSelectedUsers(users);
  };

  const submitHandler = () => {
    const usersEmails = selectedUsers.map((user) => user.email);
    let data = {
      UID: UID,
      emails: usersEmails,
      users: selectedUsers,
    };

    if (isExpiryDate) {
      const expiryDateInSec = dateInSecond(expiryDate, true).toISOString();
      data.expiryDate = expiryDateInSec;
    } else {
      data.expiryDate = null;
    }

    if (isPasswordProtected) {
        //if password not null
      if (password) {
        data.password = password;
      }
      data.hasPassword = true;
    } else {
      data.hasPassword = false;
    }

    setSubmitLoading(true);
    dispatch(linkSharedToUsers({ id: linkDetails?._id, data }))
      .then((res) => {
        if (res && res.payload) {
          closeShareDialog();
          setSelectedUsers(
            userList.filter((user) => linkDetails.emails.includes(user.email))
          );
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const closeShareDialog = () => {
    handleClose();
    setLinkDetails({});
    setSelectedUsers([]);
    setPassword(null);
    setExpiryDate(null);
    setIsPasswordProtected(false);
    setIsExpiryDate(false);
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getLinkDetails({ type: type, id: shareObjectId, version: version })
      ).then((res) => {
        if (res && res.payload) {
          setLinkDetails(res.payload);
          const { emails, users, hasPassword, expiryDate } = res.payload;
          setSelectedUsers(users)
          setIsPasswordProtected(hasPassword);
          if (expiryDate) {
            const getDate = formatIsoDate(expiryDate);
            setExpiryDate(getDate);
            setIsExpiryDate(true);
          }
        }
      });
    }
  }, [isOpen]);
  
  //check if password checbox is checked and password === null
  const isPasswordNotFill = !linkDetails.hasPassword && isPasswordProtected && !password; 
  const isExpiryDateNotSelect = isExpiryDate && !expiryDate; //check if expiry date checbox is checked and expiryDate === null

  //check 2 conditions at once, if any one is falsy button will be disabled
  const disableShareButton = isPasswordNotFill || isExpiryDateNotSelect;

  return (
    <Main
      background="white"
      className="user-dialog"
      modalWidth="md"
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="600px"
    >
      <Typography variant="h1">Share Link</Typography>
      <Box className="horizontal-line"></Box>
      <Box className="user-content">
        <Grid2 container className="" alignItems="end" spacing={1}>
          <Grid2 item size="grow">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography component="span" variant="h3">
                Share Link For :
              </Typography>
              <Typography component="span">Version : {version} </Typography>
            </Box>
            <Input
              name="sharedLink"
              className="border"
              value={
                linkDetails?.links?.previewHash
                  ? linkFormat(linkDetails.links.previewHash)
                  : ""
              }
              type="text"
              size="small"
              refer={linkRef}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
          </Grid2>
          <Grid2 item size={1}>
            <TooltipWrapper title="Copy link">
              <IconButton
                size="small"
                onClick={() => {
                  copyText(linkRef.current.value);
                }}
              >
                <AiOutlineCopy></AiOutlineCopy>
              </IconButton>
            </TooltipWrapper>
          </Grid2>
        </Grid2>

        <Grid2 container className="marginY">
          <Grid2 item size={6}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setIsPasswordProtected(e.target.checked);
                  }}
                  checked={isPasswordProtected}
                  size="small"
                />
              }
              value={isPasswordProtected}
              label="Protect the link with password"
            />
          </Grid2>
          <Grid2 item size={6}>
            <Input
              name="password"
              inputLabel={
                linkDetails.hasPassword ? "Change Password" : "Password"
              }
              onChange={passwordHandler}
              value={password}
              className="border"
              type="password"
              fullWidth
              disabled={!isPasswordProtected}
              size="small"
            />
          </Grid2>
        </Grid2>

        <Grid2 container className="marginY">
          <Grid2 item size={6}>
            <FormControlLabel
              control={
                <Checkbox
                  name="singleApproved"
                  onChange={(e) => {
                    setIsExpiryDate(e.target.checked);
                  }}
                  checked={isExpiryDate}
                  size="small"
                />
              }
              label="Set expiration date"
            />
          </Grid2>
          <Grid2 item size={6}>
            <Input
              name="deadline"
              onChange={expireDateHandler}
              value={expiryDate}
              className="border"
              type="date"
              // inputProps={{ min: disablePastDate() }}
              disabled={!isExpiryDate}
              size="small"
              fullWidth
            />
          </Grid2>
        </Grid2>

        <Typography component="span" variant="h3">
          Users
        </Typography>

        {selectedUsers && (
          <UserList
            selectedUsers={selectedUsers}
            userList={userList}
            onChange={usersChangeHanlder}
          ></UserList>
        )}
      </Box>

      <Box className="buttons">
        <Button
          //  startIcon={<IoCloseSharp />}
          className="btn"
          variant="outlined"
          onClick={closeShareDialog}
        >
          Cancel
        </Button>
        <Button
          //  startIcon={<IoSend />}
          className="btn"
          variant="contained"
          onClick={submitHandler}
          disabled={disableShareButton}
          loading={submitLoading}
        >
          Share
        </Button>
      </Box>
    </Main>
  );
};

export default React.memo(ShareLinkDialog);
