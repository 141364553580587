import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getHashDetails } from "src/store/actions/shareLink";
import { formatIsoDate, isDateExpire } from "src/hooks/useDateFormat";
import "./SharedLink.scss";
import CircleLoader from "src/components/ui/loader/CircleLoader";
import Centered from "src/layouts/Centered";
import ExpiredLink from "./ExpiredLink";
import AuthPasswordForm from "src/pages/sharedLink/AuthPasswordForm";
import useAuthShareLink from "./useAuthSharedLink";
import { View } from "./view/View";

const SharedLink = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const [hashDetails, setHashDetails] = useState(null);
  const [isExpiredPage, setIsExpiredPage] = useState(false);
  const [isPasswordPage, setIsPasswordPage] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const { isSharedLinkAuthenticated, isLoggedin } = useAuthShareLink();
  const dispatch = useDispatch();

  useEffect(() => {
    const isAuth = isSharedLinkAuthenticated(hash);
    if (isAuth) {
      console.log("user registered", isAuth);
      setHashDetails(isAuth);
      setIsRegistered(true);
    } else {
      dispatch(getHashDetails(hash)).then((res) => {
        if (res.payload) {
          console.log(res.payload);
          setHashDetails(res.payload);
        }
      });
    }
  }, [dispatch, hash]);

  useEffect(() => {
    if (hashDetails) {
      if (hashDetails.expiryDate) {
        const expireDateTime = formatIsoDate(hashDetails.expiryDate);
        if (expireDateTime && isDateExpire(expireDateTime)) {
          setIsExpiredPage(true);
          return;
        }
      }

      if (hashDetails.hasPassword) {
        setIsPasswordPage(true);
      }
    }
  }, [hashDetails, navigate]);
  
  const renderView = ()=>{
    if(!hashDetails){
      return <CircleLoader />
    }

    if(isExpiredPage){
      return <ExpiredLink />
    }

    if(isLoggedin || isRegistered || !isPasswordPage){
      return <View hashDetails={hashDetails} />
    }

    if(isPasswordPage || !isLoggedin && !isRegistered){
      return <AuthPasswordForm hashDetails={hashDetails} />
    }
  }

  return (
    <Centered>
      {renderView()}
    </Centered>
  );
};

export default SharedLink;
